.PanelWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.PanelWrapper div {
    padding: 0.5vmin;
}

.SurfaceText {
    color: rgb(var(--Surface-Color-Text));
}

.InteractionButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.25vh;
    margin-bottom: 0.25vh;
    border-radius: calc(3px + 0.5vmin);
    border: 1px solid black;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.75vmin) black;
}

.InteractionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: calc(15px + 3.0vh);
    height: calc(15px + 3.0vh);
    flex-shrink: 0;
    margin-left: calc(5px + 0.5vmin);
    margin-right: calc(5px + 0.5vmin);
    border: 3px solid rgb(var(--Surface-Color-Text));
    border-radius: 100%;
    color: rgb(var(--Surface-Color-Text));
}

.InteractionButton.NoBorder {
    border: 0px;
}

.InteractionButton:hover {
    filter: brightness(120%);
}

.InteractionButton:active {
    filter: brightness(90%);
}

.InteractionButton > div {
    font-size: calc(10px + 2vmin);
}

.InteractionButton.Primary.Active {
    border: 3px solid rgb(var(--Primary-Color));
    color: rgb(var(--Primary-Color));
}
.InteractionButton.Secondary.Active {
    border: 3px solid rgb(var(--Secondary-Color));
    color: rgb(var(--Secondary-Color));
}
.InteractionButton.Affirmative.Active {
    border: 3px solid rgb(var(--Affirmative-Color));
    color: rgb(var(--Affirmative-Color));
}
.InteractionButton.Error.Active {
    border: 3px solid rgb(var(--Error-Color));
    color: rgb(var(--Error-Color));
}