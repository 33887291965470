.PanelWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.SurfaceWrapper {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding: 1.25vmin;
    height: 100%;
    max-height: 70vh;
    border-radius: 5px;
    background: linear-gradient(to top right, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
    color: rgb(var(--Surface-Color-Text));
}

@media only screen and (min-width: 768px) {
    .SurfaceWrapper {
        max-height: 50vh;
    }
}

.BackgroundWrapper {
    display: flex;
    flex-direction: column;
    padding: 1.0vmin;
    text-align: center;
}


.Header {
    font-size: calc(10px + 1.0vmin);
    color: rgb(var(--Surface-Color-Text));
    word-wrap: break-word;
}

.Footer {
    display: flex;
    align-self: center;
    justify-self: center;
    justify-content: center;
    width: 100%;
}

.Footer > div{
    width: 50%;
    margin-top: calc(2px + 0.25vmin);
    margin-bottom: calc(2px + 0.25vmin);
}

.Footer > button {
    width: 50%;
    margin-top: calc(2px + 0.25vmin);
    margin-bottom: calc(2px + 0.25vmin);
}

.PollingField {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
    line-height: normal;
    word-wrap: break-word;
    text-align: left;
    font-size: calc(8px + 0.8vmin);
    padding: 1.0vmin;
    margin: 0.45vmin;
    border-radius: 5px;
    background: linear-gradient(to top right, rgb(var(--Surface-Color-Light)), rgb(var(--Surface-Color-Very-Light)));
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.PollingField.Active {
    background: linear-gradient(to top right, rgb(var(--Secondary-Color)), rgb(var(--Secondary-Color-Light)));
    color: var(--Secondary-Color-Text);
}

.PollingField:hover {
    filter: brightness(120%);
}
.PollingField:active {
    filter: brightness(90%);
}

.PollingField > div:nth-child(2) {
    width: 90%;
}

.PollingField > div:nth-child(1) {
    flex-grow: 1;
}

.ScrollShadow {
    overflow: hidden;
    position: absolute;
    text-align: center;
    z-index: 8;
    left: 0;
    width: 100%;
    height: 0;
    font-size: calc(10px + 0.15vmin);
    color: rgb(var(--Secondary-Color));
    transition: 750ms;
    box-shadow: none;
}

.ScrollShadow.Top {
    top: 0;
    background: linear-gradient(to bottom, rgba(var(--Shadow-Color), 0.8), rgba(var(--Shadow-Color), 0.55));
}

.ScrollShadow.Bottom {
    bottom: 0;
    background: linear-gradient(to top, rgba(var(--Shadow-Color), 0.8), rgba(var(--Shadow-Color), 0.55));
}

.ScrollShadow.Bottom.Active {
    display: block;
    height: calc(15px + 0.15vmin);
    box-shadow: 0px calc(-1px - 0.05vmin) calc(20px + 1.75vmin) 15px rgba(var(--Shadow-Color), 0.6);
}

.ScrollShadow.Top.Active {
    display: block;
    height: calc(15px + 0.15vmin);
    box-shadow: 0px calc(1px + 0.05vmin) calc(20px + 1.75vmin) 15px rgba(var(--Shadow-Color), 0.6);
}

