
.IndentHeaderWrapper > .CallerText {
    font-size: 2.0vw;
}

.OutgoingCallInnerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 1%;
    border: 1px solid black;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
    padding-top: 2.5vh;
    margin-bottom: 3vh;
}

.OutgoingCallInnerWrapper > img {
    align-self: center;
    width: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    border-radius: 100%;
    border: 2px solid var(--primary-color);
}

.OutgoingCallInnerWrapper > div {
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    padding: 1.5vmin;
    width: 50%;
}

.OutgoingCallInnerWrapper > div > .TransparentButton {
    padding: 0.5vmin;
}