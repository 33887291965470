.ProfileImage {
    align-self: center;
    width: 66%;
    border-radius: 100%;
    border: calc(2px + 0.15vmin) solid rgba(var(--Border-Color), var(--Border-Alpha));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.25vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.ProfileImageButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 1.5vmin;
    padding-bottom: 1.5vmin;
}

.ProfileImageButtonWrapper > * {
    flex-grow: 1;
    text-align: center;
}

.ProfileImageButtonWrapper > * {
    padding: 0.5vmin;
}


.ProfileImageButtonWrapper > div, .ProfileImageButtonWrapper > label {
    padding: 0.75vmin;
    flex-basis: 35%;
    flex-grow: 0;
    flex-shrink: 0;
   
}