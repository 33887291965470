.MediaPermissionWrapperOuter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    padding: 3vmin;
    background-color: var(--Background-Color);
}

.MediaPermissionWrapperInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    align-self: center;
    border-radius: 0.4vmin;
    background: linear-gradient(to bottom right, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.MediaPermissionWrapperInner > div {
    text-align: center;
    align-self: center;
    filter: brightness(120%);
    color: rgb(var(--Surface-Color-Text));
}

.MediaPermissionWrapperInner > .Primary {
    font-size: 10vmin;
}

.MediaPermissionWrapperInner > .Secondary {
    font-size: 3vmin;
}

.MediaPermissionWrapperInner > .Primary.Error {
    filter: brightness(120%);
    color: rgb(var(--Error-Color));
}