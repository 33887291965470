.Main {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100vw;
    height: 100vh;
}

.AccountName {
    align-self: center;
    padding-left: calc(5px + 0.25vmin);
    padding-right: calc(5px + 0.25vmin);
    font-size: calc(10px + 1.2vmin);
    opacity: 0.8;
}

.RightPanelWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: var(--Background-Color);
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
    max-height: 100%;
}

.RightPanelWrapper.Standby, .RightPanelBody.Standby {
    border: 0.25vmin solid rgb(var(--Standby-Color));
}
.RightPanelWrapper.Screening, .RightPanelBody.Screening {
    border: 0.25vmin solid rgb(var(--Screening-Color));
}
.RightPanelWrapper.OffAir, .RightPanelBody.OffAir {
    border: 0.25vmin solid rgb(var(--Off-Air-Color));
}
.RightPanelWrapper.OnAir, .RightPanelBody.OnAir {
    border: 0.25vmin solid rgb(var(--On-Air-Color));
}


.RightPanelHeader {
    position: absolute;
    top: 0;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    width: 0;
    min-width: 100%;
    height: calc(50px + 5vmin);
    background: linear-gradient(to top right, rgba(var(--Surface-Color), var(--Alpha-Level)), rgba(var(--Surface-Color-Light), var(--Alpha-Level)));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) rgba(var(--Shadow-Color), min(var(--Shadow-Alpha), var(--Alpha-Level)));
    border-bottom: 1px solid rgba(var(--Shadow-Color), min(var(--Border-Alpha), var(--Alpha-Level)));
    transition: 300ms;
}

.RightPanelHeader.Hidden {
    overflow: hidden;
    height: 0px;
}

.SurfaceText {
    color: rgb(var(--Surface-Color-Text));
}

.HeaderLogoWrapper {
    height: 100%;
    width: auto;
}

.HeaderLogo {
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
}

.RightPanelBody {
    z-index: 1;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 100%;
    max-width: 100%;
    background: var(--Background-Color);
    min-height: 0;
}

.RightPanelFooter {
    position: absolute;
    bottom: 0;
    z-index: 3;
    display: flex;
    width: 0;
    min-width: 100%;
    height: calc(25px + 5vmin);
    background: linear-gradient(to bottom right, rgba(var(--Surface-Color), var(--Alpha-Level)), rgba(var(--Surface-Color-Light), var(--Alpha-Level)));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) rgba(var(--Shadow-Color), min(var(--Shadow-Alpha), var(--Alpha-Level)));
    border-top: 1px solid rgba(var(--Shadow-Color), min(var(--Border-Alpha), var(--Alpha-Level)));
    transition: 300ms;
}

.RightPanelFooter.Hidden {
    overflow: hidden;
    height: 0px;
}


.PollingWrapper {
    position: absolute;
    bottom: 0;
    z-index: 21;
    width: 100%;
    transition: 300ms;
}

.PollingWrapper.Left {
    left: 0;
}

.PollingWrapper.Right {
    right: 0;
}

.PollingWrapper.Offset {
    bottom: calc(25px + 5vmin);
}
    
.RightPanelHeader.Fixed {
    position: relative;
    z-index: 1;
    background: linear-gradient(to top right, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
}

.RightPanelFooter.Fixed {
    position: relative;
    z-index: 1;
    background: linear-gradient(to bottom right, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
}

@media only screen and (min-width: 768px) {
    .PollingWrapper {
        width: 35%;
        margin: calc(3px + 0.2vmin);
    }

}
