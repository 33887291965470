.ConfigurationPanelWrapperOuter {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: var(--Background-Color);
}

.ConfigurationPanelWrapperUpper{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
}
.ConfigurationPanelWrapperLower{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%;
}

.ConfigurationPanelTextWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: calc(3.2vmin);
    color: rgb(var(--Surface-Color-Text));
}

.LocalVideoWrapper {
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    /*flex-basis: 45%;*/
    align-self: center;
    flex-grow: 1;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 16 / 9;
    min-width: 192px;
    min-height: 108px;
    padding: calc(25px + 0.25vmin);
}

.IsProcessingWrapper {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15vh;
}

.IsProcessingGraphic {
    position: absolute;
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
    align-items: center;
    background-color: #00000088;
    z-index: 10;
    width: 10vh;
    height: 10vh;
    animation-name: pulsateProcessingBorder;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    border-radius: 100% 100%;
}

.IsProcessingGraphicInner {
    animation-name: pulsateProcessingColor;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    font-size: 2.2vh;
    text-align: center;
}

.Header {
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    width: 100%;
    height: calc(10vh);
    background: linear-gradient(to top right, rgba(var(--Surface-Color), var(--Alpha-Level)), rgba(var(--Surface-Color-Light), var(--Alpha-Level)));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
    border-bottom: 1px solid rgba(var(--Shadow-Color), var(--Border-Alpha));
}

.HeaderLogoWrapper {
    height: 100%;
    width: auto;
}

.HeaderLogo {
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
}

@keyframes pulsateProcessingBorder {
    0% {
        border: 0.25vh solid rgb(var(--Primary-Color));
    }
    50% {
        border: 0.25vh solid rgb(var(--Secondary-Color));
    }
    100% {
        border: 0.25vh solid rgb(var(--Primary-Color));
    }
}

@keyframes pulsateProcessingColor {
    0% {
        color: rgb(var(--Primary-Color));
    }
    50% {
        color: rgb(var(--Secondary-Color));
    }
    100% {
        color: rgb(var(--Primary-Color));
    }
}
.LocalVideo {
    position: absolute;
    object-fit: contain;
    width: auto;
    z-index: 1;
    aspect-ratio: 16 / 9;
    max-width: 90%;
    height: 92%;
    padding: 0.1vmin;
    background-color: black;
    border-radius: 0.3vmin;
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.LocalVideoWrapper .VideoFraming {
    position: absolute;
    object-fit: contain;
    width: auto;
    z-index: 2;
    aspect-ratio: 16 / 9;
    max-width: 100%;
    height: 92%;
    padding: 0.1vmin;
	filter: hue-rotate(220deg) brightness(250%);
}

.SurfaceWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding: 1.25vmin;
    margin: 0.75vmin;
    width: 98%;
    border-radius: 5px;
    color: rgb(var(--Surface-Color-Text));
    background: linear-gradient(to top right, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.SurfaceWrapper select {
    width: 100%;
    padding: calc(5px + 0.5vmin);
}

.SurfaceWrapper select.Active {
    background: linear-gradient(to bottom, rgb(var(--Secondary-Color)), rgb(var(--Secondary-Color-Light)));
    color: var(--Secondary-Color-Text);
}

.SurfaceWrapper div {
    color: rgb(var(--Surface-Color-Text));
}

.Footer {
    padding-top: calc(5px + 0.25vmin);
    padding-bottom: calc(10px + 0.5vmin);
    align-self: center;
    justify-self: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Footer div {
    width: 45%;
    font-size: calc(10px + 1.15vmin);
}

@media (orientation: landscape) {
    .ConfigurationPanelWrapperUpper{
        flex-direction: row;
    }

    .LocalVideoWrapper {
        max-width: 50%;
    }

    .SurfaceWrapper {
        width: 50%;
    }

    .ConfigurationPanelTextWrapper {
        width: 80%;
    }

    .SurfaceWrapper div {
        font-size: calc(3.2vmin);
    }
    
    .ConfigurationPanelWrapperUpper .SurfaceWrapper div {
        font-size: calc(3.5vmin);
    }

    .SurfaceWrapper select {
        font-size: calc(3.5vmin);
    }
}

@media only screen and (min-width: 768px) {
    .RightPanelHeader.Fixed {
        position: relative;
        z-index: 1;
        background: linear-gradient(to top right, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
    }

    .RightPanelFooter.Fixed {
        position: relative;
        z-index: 1;
        background: linear-gradient(to bottom right, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
    }
}
