
.ChatWindowWrapperOuter {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    width: 35%;
    z-index: 3;
    height: 100%;
    border-left: 1px solid var(--dark-background-color);
    background: 
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, rgba(0,0,0,0.55), rgba(0,0,0,0.15));
}

.ChatDisplayWrapperOuter {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
}

.ChatTabsWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-basis: 3%;
    border-bottom: 2px solid var(--primary-color);
}

.ChatTabOuter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 1%;
    border: 1px solid black;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    min-width: 0;
}

.ChatTabOuter:hover {
    filter: brightness(120%);
}

.ChatTabOuter > div:nth-child(1){
    align-self: flex-end;
    flex-shrink: 1;
    flex-grow: 1;
    text-overflow: ellipsis;
    min-width: 0;
    overflow: hidden;
}

.ChatTabOuter > div:nth-child(2) {
    align-self: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
}

.ChatTabOuter > div:nth-child(2):hover {
    color: var(--light-primary-color); 
}

.ChatTabOuter.active {
    color: white;
}
.ChatTabOuter.inactive {
    color: var(--light-background-color);
}

.ChatTabOuter.inactive.hasNewMessages {
    color: var(--warning-color);
}

.ChatDisplayTextAreaWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1vmin;
    color: white;
    font-weight: 400;
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: scroll;
}

.ChatDisplaySendBoxWrapper {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 5%;
    border-top: 2px solid var(--primary-color);
}

.ChatDisplaySendBoxWrapper > input {
    width: 100%;
    height: 100%;
    padding: 0.5vmin;
    background:
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to top right, rgba(50,50,50,0.55), rgba(80,80,80,0.5));
    border: none;
    outline: none !important;
    color: white;
}


.MessageWrapper {
    max-width: 90%;
    padding: 1.0vmin;
    margin: 0.1vmin;
    border-radius: 1vmin;
    border: 1px solid var(--darker-background-color);
}

.MessageWrapper.Local {
    justify-self: right;
    align-self: flex-end;
    background-image: linear-gradient(to bottom left, var(--dark-active-color), var(--active-color));
}

.MessageWrapper.Remote {
    justify-self: left;
    align-self: flex-start;
    background-image: linear-gradient(to bottom left, var(--primary-color), var(--light-primary-color));
}