.ToggleSwitchWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.ToggleSwitchWrapper .Label {
    transition: 1s;
    margin-left: 0.5vmin;
    font-weight: 500;
}

.ToggleSwitchWrapper .Label.Inactive {
    color: rgb(var(--Surface-Color-Text));
}

.ToggleSwitchWrapper .Label.Active {
    color: rgb(var(--Secondary-Color));
}

.ToggleSwitch {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: calc(25px + 1.0vmin);
    height: calc(12px + 0.6vmin);
}

.ToggleSwitch .SwitchBody {
    transition: 1s;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 50%;
    border-radius: 20% / 100%;
    border: 2px solid rgba(var(--Shadow-Color),0.2);
    background-color: rgba(var(--Surface-Color-Text),0.15);
}

.ToggleSwitch.Active .SwitchBody{
    background-color: rgba(var(--Secondary-Color), var(--Alpha-Level));
}

.ToggleSwitch .Slider {
    transition: 1s;
    position: absolute;
    top: 0;
    width: calc(12px + 0.6vmin);
    height: calc(12px + 0.6vmin);
    border-radius: 100%;
    border: 2px solid rgba(var(--Shadow-Color),0.2);
}

.ToggleSwitch.Inactive .Slider {
    background-color: rgba(var(--Surface-Color-Text), 1.0);
    left: 0;
}

.ToggleSwitch.Active .Slider {
    right: 0;
    background-color: rgb(var(--Secondary-Color));
}