.TextField {
    position: relative;
    flex-grow: 1;
    margin-top: 10px;
    margin-bottom: 10px;
}

.FieldLabel {
    text-align: center;
    position: absolute;
    top: calc(-0.505em + -0.05vmin);
    left: 0.8vmin;
    width: auto;
    color: rgb(var(--Primary-Color));
    white-space: nowrap;
}

.TextField input {
    background-color: transparent;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(var(--Primary-Color));
    outline: none;
    padding: calc(1.01em + 0.1vmin);
    /*clip-path: polygon(0% 0%, 0.7vmin 0%, 0.7vmin 8%, calc(34px + 1.25vmin + 1.7vmin) 8%, calc(34px + 1.25vmin + 0.7vmin) 0%, 100% 0%, 100% 99%, 0% 100%);*/
    color: rgb(var(--Surface-Color-Text));
    font-weight: 400;
    font-size: calc(10px + 0.45vmin);
}

.TextField input::placeholder {
    color: rgba(var(--Surface-Color-Text), 0.7);
}

.TextField.Medium input {
}
.TextField.Big input {
}
.TextField.Huge input {
}
