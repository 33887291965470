.ToggleChatWindowButtonOuterWrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 20%;
    height: 80%;
    align-self: center;
    border-radius: 4vmin;
    padding: 0.4vmin;
    border: 1px solid var(--darker-background-color);
    filter: brightness(110%);
    box-shadow: inset calc(-1px - 0.05vmin) calc(1px + 0.05vmin) calc(10px + 0.05vmin) var(--normal-background-color);
}

.ToggleChatWindowButtonInnerWrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 100%;
    border: 1px solid var(--dark-background-color);
    border-radius: 4vmin;
    transition: 0.5s;
    background: 
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--extremely-light-background-color), var(--very-light-background-color));
    box-shadow: 
        inset 0 4px calc(6px + 4vmin) var(--normal-background-color),
        inset 0 0 calc(10px + 0.5vmin) black,
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 rgba(0,0,0,0.25),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 rgba(0,0,0,0.15);
}

.ToggleChatWindowButtonText {
    align-self: center;
}

.ToggleChatWindowButtonInnerWrapper.active, .ToggleChatWindowButtonInnerWrapper:active {
    filter: brightness(130%) !important;
    border-color: var(--dark-background-color);
    box-shadow:
        inset 0 0 calc(10px + 0.2vmin) black,
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 rgba(0,0,0,0.25),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 rgba(0,0,0,0.25),
        inset 0 calc(2px + 0.30vmin) calc(10px + 0.75vmin) 0 var(--dark-background-color) !important;
}

.ToggleChatWindowButtonInnerWrapper.inactive {
    filter: brightness(50%) !important;
    box-shadow: 
        inset 0 4px calc(6px + 4vmin) var(--normal-background-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color) !important;
    background: 
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--extremely-light-background-color), var(--very-light-background-color)) !important;
}

.ToggleChatWindowButtonInnerWrapper:hover {
    filter: brightness(100%);
}

.ToggleChatWindowButtonInnerWrapper.BlueHaze.Static, .ToggleChatWindowButtonInnerWrapper.BlueHaze:hover {
    box-shadow: 
        inset 0 4px calc(6px + 4vmin) var(--light-primary-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color),
        calc(1px + 0.1vmin) calc(1px + 0.1vmin) calc(5px + 0.2vmin) black;
    background:
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--very-light-primary-color));
}

.ToggleChatWindowButtonInnerWrapper.BlueHaze.active, .ToggleChatWindowButtonInnerWrapper.BlueHaze:active {
    background: 
        url('../../../Assets/BackgroundNoise.png'),
        radial-gradient(var(--light-primary-color) 25%, var(--light-primary-color));
}


.ToggleChatWindowButtonInnerWrapper.YellowHaze.Static, .ToggleChatWindowButtonInnerWrapper.YellowHaze:hover {
    box-shadow: 
        inset 0 4px calc(6px + 4vmin) var(--light-warning-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color),
        calc(1px + 0.1vmin) calc(1px + 0.1vmin) calc(5px + 0.2vmin) black;
    background:
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--very-light-warning-color));
}

.ToggleChatWindowButtonInnerWrapper.YellowHaze.active, .ToggleChatWindowButtonInnerWrapper.YellowHaze:active {
    background: 
        url('../../../Assets/BackgroundNoise.png'),
        radial-gradient(var(--light-warning-color) 25%, var(--light-warning-color));
}