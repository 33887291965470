
.ConnectingWrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(50vh - 67.5px - 2.5vw);
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
    align-items: center;
    width: calc(135px + 5vw);
    height: calc(135px + 5vw);
    animation-name: pulsateConnectBorder;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    border-radius: 100% 100%;
}

.ConnectingWrapper > div {
    animation-name: pulsateConnectColor;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    font-size: 1.2em;
    text-align: center;
}

@keyframes pulsateConnectBorder {
    0% {
        border: 3px solid rgb(var(--Primary-Color));
    }
    50% {
        border: 3px solid rgb(var(--Secondary-Color));
    }
    100% {
        border: 3px solid rgb(var(--Primary-Color));
    }
}

@keyframes pulsateConnectColor {
    0% {
        color: rgb(var(--Primary-Color));
    }
    50% {
        color: rgb(var(--Secondary-Color));
    }
    100% {
        color: rgb(var(--Primary-Color));
    }
}
