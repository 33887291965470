.PanelWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.SurfaceWrapper {
    display: flex;
    flex-direction: column;
    padding: 1.25vmin;
    margin: 0.75vmin;
    border-radius: 5px;
    background: linear-gradient(to top right, rgb(var(--Surface-Color-Light)), rgb(var(--Surface-Color-Very-Light)));
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.BackgroundWrapper {
    display: flex;
    flex-direction: column;
    padding: 1.25vmin;
    margin: 0.75vmin;
    text-align: center;
}

.Error {
    font-size: calc(10px + 0.75vmin);
    color: rgb(var(--Error-Color));
}

.Header {
    font-size: calc(25px + 1.5vmin);
    color: rgb(var(--Surface-Color-Text));
}

.Footer {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-self: center;
    justify-self: center;
    justify-content: center;
    width: 100%;
}

.PanelFooter.Scroll {
    position: relative !important;
}

.Footer > div{
    width: 50%;
    margin-top: calc(2px + 0.25vmin);
    margin-bottom: calc(2px + 0.25vmin);
}

.Footer > button {
    width: 50%;
    margin-top: calc(2px + 0.25vmin);
    margin-bottom: calc(2px + 0.25vmin);
}

.ScrollShadow {
    overflow: hidden;
    position: absolute;
    text-align: center;
    z-index: 8;
    bottom: 0;
    width: 100%;
    height: 0;
    font-size: calc(10px + 0.15vmin);
    color: rgb(var(--Secondary-Color));
    transition: 750ms;
    box-shadow: none;
}

.ScrollShadow.Top {
    Top: 52px;
    background: linear-gradient(to bottom, rgba(var(--Shadow-Color), 0.8), rgba(var(--Shadow-Color), 0.55));
}

.ScrollShadow.Bottom {
    bottom: 0;
    background: linear-gradient(to top, rgba(var(--Shadow-Color), 0.8), rgba(var(--Shadow-Color), 0.55));
}

.ScrollShadow.Bottom.Active {
    display: block;
    height: calc(15px + 0.15vmin);
    box-shadow: 0px calc(-1px - 0.05vmin) calc(20px + 1.75vmin) 15px rgba(var(--Shadow-Color), 0.6);
}

.ScrollShadow.Top.Active {
    display: block;
    height: calc(15px + 0.15vmin);
    box-shadow: 0px calc(1px + 0.05vmin) calc(20px + 1.75vmin) 15px rgba(var(--Shadow-Color), 0.6);
}

@media only screen and (min-width: 768px) {
    .Footer {
        position: relative;
    }
}

@media only screen and (orientation: landscape) {
    .Footer {
        position: relative;
    }
}
