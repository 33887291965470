
.ShowButtonWrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    flex-basis: 3%;
    width: 75%;
    margin: 2.5%;
    flex-grow: 0;
    flex-shrink: 0;
}

.ShowButton:hover {
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--bright-white-color), 0 0 calc(15px + 0.7vmin) var(--very-light-primary-color);
}

.ShowButton:active {
    box-shadow: inset 0 0 calc(20px + 0.45vmin) var(--dark-background-color);
    border: 3px solid var(--dark-background-color);
	filter: brightness(75%);
}

.ShowButton.Disconnect, .ShowButton.LeaveShow {
    background-image: linear-gradient(to top, var(--dark-inactive-color), var(--inactive-color));
}

.ShowButton.JoinShow {
    background-image: linear-gradient(to top, var(--primary-color), var(--light-primary-color));
}