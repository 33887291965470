.LeftPanelWrapperOuter {
    overflow: hidden;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    flex-shrink: 0;
    background: linear-gradient(to left, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
    transition: 300ms;
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
    border-right: 1px solid rgba(var(--Shadow-Color), var(--Border-Alpha));
}

.LeftPanelWrapperOuter.Hidden {
    display: none;
    width: 0px;
}

.LeftPanelHeader {
    position: relative;
    z-index: 15;
    display: flex;
    background: linear-gradient(to bottom, rgb(var(--Primary-Color)), rgb(var(--Primary-Color-Light)));
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 15px;
    padding-bottom: 15px;
    color: var(--Primary-Color-Text);
    justify-content: space-between;
}

.LeftPanelHeader div {
    width: 20%;
    text-align: center;
}

.LeftPanelHeader div:first-child {
    text-align: left;
}

.LeftPanelHeader div:last-child {
    text-align: right;
}

.LeftPanelWrapper {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.PanelFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-self: center;
    justify-self: center;
    justify-content: center;
    width: 100%;
}

.PanelFooter.Scroll {
    position: relative !important;
}

.ScrollShadow {
    overflow: hidden;
    position: absolute;
    text-align: center;
    z-index: 8;
    bottom: 0;
    width: 100%;
    height: 0;
    font-size: calc(10px + 0.15vmin);
    color: rgb(var(--Secondary-Color));
    transition: 750ms;
    box-shadow: none;
}

.ScrollShadow.Top {
    Top: 52px;
    background: linear-gradient(to bottom, rgba(var(--Shadow-Color), 0.8), rgba(var(--Shadow-Color), 0.55));
}

.ScrollShadow.Bottom {
    bottom: 0;
    background: linear-gradient(to top, rgba(var(--Shadow-Color), 0.8), rgba(var(--Shadow-Color), 0.55));
}

.ScrollShadow.Bottom.Active {
    display: block;
    height: calc(15px + 0.15vmin);
    box-shadow: 0px calc(-1px - 0.05vmin) calc(20px + 1.75vmin) 15px rgba(var(--Shadow-Color), 0.6);
}

.ScrollShadow.Top.Active {
    display: block;
    height: calc(15px + 0.15vmin);
    box-shadow: 0px calc(1px + 0.05vmin) calc(20px + 1.75vmin) 15px rgba(var(--Shadow-Color), 0.6);
}

.PanelFooter > div {
    width: 50%;
    margin-bottom: calc(2px + 0.25vmin);
}

.SecondaryPanel {
    position: relative;
    overflow: hidden;
    max-height: 35%;
    min-height: 250px;
    margin: 0.75vmin;
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.Button:hover {
    filter: brightness(120%);
}

.Button:active {
    filter: brightness(80%);
}

.Button.Active {
    filter: brightness(120%);
    font-weight: 700;
}

@media only screen and (min-width: 768px) {
    .PanelFooter {
        position: relative;
    }
    .LeftPanelWrapperOuter {
        width: calc(384px + 5vw);
        background: linear-gradient(to left, rgba(var(--Surface-Color), var(--Panel-Alpha-Level)), rgba(var(--Surface-Color-Light), var(--Panel-Alpha-Level)));
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) rgba(var(--Shadow-Color), min(var(--Shadow-Alpha), var(--Panel-Alpha-Level)));
        border-right: 1px solid rgba(var(--Shadow-Color), min(var(--Border-Alpha), var(--Panel-Alpha-Level)));
    }
    .LeftPanelWrapperOuter.Fixed {
        position: relative;
        z-index: 4;
        background: linear-gradient(to left, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
        box-shadow: none;
        border-radius: 0;
    }

    .LeftPanelWrapperOuter.Hidden {
        display: block;
        width: 0px;
    }
}

@media only screen and (orientation: landscape) {
    .PanelFooter {
        position: relative;
    }
}
