.LocalVideoWrapper {
	position: absolute;
	z-index: 20;
	display: flex;
	align-items: center;
	justify-content: center;
	/*flex-basis: 45%;*/
	align-self: center;
	max-width: 100%;
	width: 16vw;
	height: 9vw;
	min-width: 192px;
	min-height: 108px;
	transition: 300ms;
}

.LocalVideoWrapper.Top.Offset {
	top: calc(50px + 5vmin);
}
.LocalVideoWrapper.Top {
	top: 0;
}
.LocalVideoWrapper.Bottom.Offset {
	bottom: calc(25px + 5vmin);
}
.LocalVideoWrapper.Bottom {
	bottom: 0;
}
.LocalVideoWrapper.Left {
	left: 0;
}
.LocalVideoWrapper.Right {
	right: 0;
}

.IsProcessingWrapper {
	position: relative;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 15vh;
}

.IsProcessingGraphic {
	position: absolute;
	display: flex;
	justify-content: center;
	align-self: center;
	justify-self: center;
	align-items: center;
	background-color: #00000088;
	z-index: 10;
	width: 10vh;
	height: 10vh;
	animation-name: pulsateProcessingBorder;
	animation-duration: 3.5s;
	animation-iteration-count: infinite;
	border-radius: 100% 100%;
}

.IsProcessingGraphicInner {
	animation-name: pulsateProcessingColor;
	animation-duration: 3.5s;
	animation-iteration-count: infinite;
	font-size: 1.2em;
	text-align: center;
}

@keyframes pulsateProcessingBorder {
	0% {
		border: 5px solid rgb(var(--Primary-Color));
	}
	50% {
		border: 5px solid rgb(var(--Secondary-Color));
	}
	100% {
		border: 5px solid rgb(var(--Primary-Color));
	}
}

@keyframes pulsateProcessingColor {
	0% {
		color: rgb(var(--Primary-Color));
	}
	50% {
		color: rgb(var(--Secondary-Color));
	}
	100% {
		color: rgb(var(--Primary-Color));
	}
}
.LocalVideo {
	position: absolute;
	object-fit: contain;
	width: auto;
	z-index: 1;
	aspect-ratio: 16 / 9;
	max-width: 100%;
	height: 92%;
	padding: 0.1vmin;
	background-color: black;
	border-radius: 0.3vmin;
	border: 1px solid rgba(var(--Shadow-Color), var(--Border-Alpha));
	box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.LocalVideoWrapper.Hidden {
	display: none;
}

.LocalVideoWrapper .VideoFraming {
	position: absolute;
	object-fit: contain;
	width: auto;
	z-index: 2;
	aspect-ratio: 16 / 9;
	max-width: 100%;
	height: 92%;
	padding: 0.1vmin;
	filter: hue-rotate(220deg) brightness(250%);
}

.VideoQuadrantAdjusterWrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	position: absolute;
	object-fit: contain;
	width: auto;
	z-index: 3;
	aspect-ratio: 16 / 9;
	max-width: 100%;
	height: 92%;
	padding: 0.1vmin;
}
.VideoQuadrantAdjusterWrapper:hover div {
	border: 1px solid black;
}
.VideoQuadrantAdjusterWrapper div {
	width: 50%;
	height: 50%;
}

.VideoQuadrantAdjusterWrapper div:hover {
	background: rgba(var(--Primary-Color), 0.4);
}

.VideoQuadrantAdjusterWrapper div:active{
	background: rgba(var(--Primary-Color), 0.8);
	filter: brightness(120%);
}
